import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import featureFlags from "utils/featureFlags.js";

import InputField from "../../../inputField";
import ColorPicker from "../../../colorPicker";

import StyledDropzone from "components/chooseImage";

const Form = ({
  data,
  inputChange,
  editImage,
  onFileUpload,
  handleColorChange,
  setDefaulImageHandler,
  uploadFileRegex
}) => {
  let designData = Object.keys(data || {}).map((input, index) => {
    let designDataValue = data[input];

    if (designDataValue.type === "file") {
      let dimensions = designDataValue.dimensions;

      let updatedDimensions = {
        top: dimensions.y || 0,
        left: dimensions.x || 0,
        height:
          dimensions.height || dimensions.height === 0
            ? dimensions.height
            : 100,
        width:
          dimensions.width || dimensions.width === 0 ? dimensions.width : 100
      };

      return (
        <Col key={index} className="half-width file-container">
          <StyledDropzone
            title={designDataValue.label}
            path={
              designDataValue.cropped.location ||
              designDataValue.original.location ||
              ""
            }
            originalImagePath={designDataValue.original.location || ""}
            handleEdit={(img, data) => editImage(img, data, input)}
            imageDetails={updatedDimensions}
            onFileUpload={file => onFileUpload(file, input)}
            showDefault={designDataValue.showDefaultFile}
            setDefaulImageHandler={setDefaulImageHandler}
            isDisabled={designDataValue.isDefaultImage}
            acceptedFileType={".jpg, .jpeg, .png"}
            uploadFileRegex={uploadFileRegex}
          />
          {designDataValue.error && <Error>{designDataValue.error}</Error>}
        </Col>
      );
    } else if (designDataValue.type === "color") {
      if (
        designDataValue.id === "secondary" &&
        featureFlags.presentation.hideSecondaryColor
      ) {
        return null;
      }

      return (
        <Col key={index} className="half-width color-picker">
          <ColorPicker
            title={designDataValue.label}
            value={designDataValue.value || ""}
            handleColorChange={value => {
              handleColorChange(value, input);
            }}
          />
        </Col>
      );
    } else {
      return (
        <InputContainer key={index}>
          <InputField
            placeholder={designDataValue.label}
            name={designDataValue.label}
            value={designDataValue.value || ""}
            error={designDataValue.error}
            type={designDataValue.type}
            handleChange={inputChange}
            id={designDataValue.id}
          />
        </InputContainer>
      );
    }
  });

  return designData;
};

export default Form;

const Col = styled.div`
  box-sizing: border-box;
  width: calc(50% - 16px);
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  position: relative;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Error = styled.span`
  position: absolute;
  color: ${props => hexToRgba(props.theme.COLOR.ERROR, "1")};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  bottom: -12px;
  left: 0;
  pointer-events: none;
`;
