import React from "react";
import container from "./container";
import Button from "../button";
import styled from "styled-components";

import Form from "./components/Form";

const Design = props => {
  let {
    handleInputChange,
    designData,
    handleEditImage,
    onFileUpload,
    saveButtonHandler,
    handleColorChange,
    cancelButtonHanlder,
    setDefaulImageHandler,
    uploadFileRegex,
  } = props;

  return (
    <Wrapper>
      <Float>
        <HeadingContainer>
          <Heading>Design</Heading>
        </HeadingContainer>
        <FloatRight>
          <ButtonWrapper>
            <Button
              text="Cancel"
              type="secondary"
              onClick={cancelButtonHanlder}
            />
          </ButtonWrapper>
          <Button text="Save" type="primary" onClick={saveButtonHandler} />
        </FloatRight>
      </Float>
      <Form
        data={designData}
        inputChange={handleInputChange}
        editImage={handleEditImage}
        onFileUpload={onFileUpload}
        handleColorChange={handleColorChange}
        setDefaulImageHandler={setDefaulImageHandler}
        uploadFileRegex={uploadFileRegex}
      />
    </Wrapper>
  );
};
export default container(Design);

const Wrapper = styled.div`
  width: 100%;
  padding: 27px 40px 40px;
  border-radius: 4px;
  background-color: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  box-sizing: border-box;

  .half-width {
    &:nth-of-type(2n) {
      margin-right: 32px;
    }
  }

  .color-picker {
    margin-bottom: 0;
  }

  .file-container {
    margin-top: 14px;
  }
`;

const Heading = styled.h2`
  display: block;
  margin-bottom: 7px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-align: left;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const Float = styled.div`
  width: 100%;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FloatRight = styled.div`
  float: right;
`;
const HeadingContainer = styled.div`
  float: left;
`;
